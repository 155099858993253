import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    height: 60px;
    color: white;
    font-family: ${props => props.theme.font4};
    background-color: ${props => props.theme.darkBlue};
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: 639px){
        font-size: 12px;
    }
`

export default () => (
    <Wrapper>
        Copyright &copy; 2020 YW Soo & Co. All rights reserved.
    </Wrapper>
)