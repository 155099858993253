import React, { useState } from "react";
import styled from "styled-components";
import Logoname from "./img/Logo_name.jpg";
import { IoMdMenu } from "react-icons/io";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  height: 70px;
  width: 100vw;
  background-color: #ffffff;
  padding: 15px 10vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  @media (max-width: 639px) {
    height: 60px;
    padding: 10px 20px;
  }
`;

const Logo = styled.div`
  height: 100%;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    transition: 0.6s;
    height: ${(props) => props.menuHeight};
    overflow: hidden;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #ffffff;
    padding: ${(props) => props.menuPadding}px 20px;
  }
`;

const MenuItem = styled(Link)`
  margin: 0px 24px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  font-family: ${(props) => props.theme.font2};
  font-size: 16px;
  transition: 0.6s;
  ::after {
    content: "";
    display: block;
    width: 0px;
    height: 2px;
    background: ${(props) => props.theme.darkBlue};
    transition: 0.6s;
    margin-top: 2px;
  }
  :hover {
    color: ${(props) => props.theme.blue};
    ::after {
      background: ${(props) => props.theme.blue};
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    line-height: 30px;
  }
`;

const BurgerMenu = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

export default ({ homeRef, serviceRef, aboutRef, careerRef, contactRef }) => {
  const [phoneMenu, setPhoneMenu] = useState(false);

  function scroll(div) {
    setPhoneMenu(false);
    window.scrollTo({ top: div - 20, behavior: "smooth" });
  }
  return (
    <Wrapper>
      <Logo>
        <img alt="Logo" src={Logoname} height="100%" />
      </Logo>
      <BurgerMenu>
        <IoMdMenu
          size={34}
          onClick={() => {
            setPhoneMenu(!phoneMenu);
          }}
        />
      </BurgerMenu>
      <MenuWrapper
        menuHeight={phoneMenu ? "auto" : "0"}
        menuPadding={phoneMenu ? "20" : "0"}
      >
        <MenuItem
          onClick={() => {
            scroll(homeRef.current.offsetTop);
          }}
        >
          {" "}
          Home
        </MenuItem>
        <MenuItem
          onClick={() => {
            scroll(serviceRef.current.offsetTop);
          }}
        >
          Services
        </MenuItem>
        <MenuItem
          onClick={() => {
            scroll(aboutRef.current.offsetTop);
          }}
        >
          About us
        </MenuItem>
        <MenuItem
          onClick={() => {
            scroll(careerRef.current.offsetTop);
          }}
        >
          Career
        </MenuItem>
        <MenuItem
          onClick={() => {
            scroll(contactRef.current.offsetTop);
          }}
        >
          Contact us
        </MenuItem>
      </MenuWrapper>
    </Wrapper>
  );
};
