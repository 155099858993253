const font1 = `'Roboto', sans-serif;`;
const font2 = `'Open Sans', sans-serif;`;
const font3 = `'Lato', sans-serif;`;
const font4 = `'Ubuntu', sans-serif;`;

export default {
  blue: "#00A0D2",
  darkBlue: "#4B70B9",
  shadow1: "0px 4px 8px rgba(80, 80, 80, 0.15)",
  shadow2: "0px 4px 12px rgba(0, 0, 0, 0.15)",
  font1: font1,
  font2: font2,
  font3: font3,
  font4: font4,
  lightGrey: "#F8F9FA",
  darkGrey: "#4A4A4A",
  blackBlue: "#393c4b",
  whiteBox: `
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: 12px;
    padding: 40px;
    `,
};
