import React, { Component } from "react";
import styled from "styled-components";
import team from "../components/img/team.png";
import Input2 from "../components/Input2";
import { IoMdArrowBack } from "react-icons/io";
import FileInput from "../components/FileInput";
import Button from "../components/Button";
import { toast } from "react-toastify";
import * as firebase from "firebase";

const Wrapper = styled.div`
  min-height: calc(100vh - 80px);
`;

const BackWrapper = styled.div`
  height: 60px;
  background-color: #ffffff;
  font-family: ${(props) => props.theme.font1};
  display: flex;
  align-items: center;
  padding: 0px 20px;
`;
const Back = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.darkBlue};
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.blue};
  }
`;

const TopWrapper = styled.div`
  background-color: #ffffff;
  border-style: solid;
  border-bottom-width: 1px;
  border-color: #e1e3e6;
  padding: 20px 20vw;
  @media (max-width: 768px) {
    padding: 20px 10vw;
  }
  @media (max-width: 639px) {
    padding: 20px;
  }
`;
const ServiceDetail = styled.p`
  font-size: 16px;
  font-family: ${(props) => props.theme.font3};
  line-height: 26px;
  cursor: default;
  margin-bottom: 16px;
  color: ${(props) => props.theme.darkGrey};
  font-weight: 400;
  @media (max-width: 639px) {
    font-size: 14px;
    line-height: 24px;
  }
`;
const JobSubtitle = styled.h3`
  font-family: ${(props) => props.theme.font4};
  font-size: 18px;
  color: ${(props) => props.theme.blackBlue};
  letter-spacing: 0.1em;
  font-weight: 700;
  margin: 16px 0px;
  text-align: center;
  @media (max-width: 639px) {
    font-size: 16px;
  }
`;

const JobTitle = styled.h1`
  font-family: ${(props) => props.theme.font3};
  font-size: 28px;
  font-weight: 900;
  margin-top: 12px;
  letter-spacing: 0.1em;
  margin-bottom: 16px;
  color: ${(props) => props.theme.blackBlue};
  @media (max-width: 639px) {
    font-size: 23px;
    letter-spacing: 0.05em;
  }
`;

const Subtitle = styled.div`
  font-size: 18px;
  font-family: "Google Sans", "Roboto", sans-serif;
  color: ${(props) => props.theme.darkGrey};
  font-weight: 700;
  cursor: default;
  margin-bottom: 8px;
  @media (max-width: 639px) {
    font-size: 16px;
  }
`;

const SectionWrapper = styled.div`
  border-radius: 8px;
  background-color: #ffffff;
  padding: 20px;
  border: solid 1px #e1e3e6;
  width: 100%;
  margin-bottom: 20px;
`;
const BottomWrapper = styled.div`
  padding: 20px 20vw;
  @media (max-width: 768px) {
    padding: 20px 10vw;
  }
  @media (max-width: 639px) {
    padding: 20px;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  padding: 20px;
`;
const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export default class Career extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      contact: "",
      dob: "",
      address: "",
      spmuec: "",
      academic: "",
      expectedSalart: "",
      doa: "",
    };
  }

  _submitForm = (e) => {
    e.preventDefault();
    let img = document.getElementById("resume").files[0];
    if (this.state.name === "") {
      return toast.error("Please enter a valid name");
    }
    if (this.state.email === "") {
      return toast.error("Please enter a valid e-mail");
    }
    if (this.state.contact === "") {
      return toast.error("Please enter a valid phone number");
    }
    if (this.state.dob === "") {
      return toast.error("Please enter a valid date of birth");
    }
    if (this.state.address === "") {
      return toast.error("Please enter a valid address");
    }
    if (this.state.spmuec === "") {
      return toast.error("Please enter a valid SPM/UEC result");
    }
    if (this.state.acedemic === "") {
      return toast.error("Please enter a valid academic result");
    }
    if (this.state.expectedSalart === "") {
      return toast.error("Please enter a valid expected salary");
    }
    if (!img) {
      return toast.error("Please upload your resume to proceed");
    }
    let productsRef = firebase.database().ref("resume");
    let newProductRef = productsRef.push();
    let storageRef = firebase
      .storage()
      .ref("resume/" + newProductRef.key + "/resume.pdf");
    let saveImg = storageRef.put(img);
    let imgtoast = null;
    saveImg.on(
      "state_changed",
      function (snapshot) {
        var imgProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (imgtoast === null) {
          imgtoast = toast(
            "Submitting Resume: " + imgProgress.toFixed(2) + "%",
            { progress: imgProgress, position: "top-right" }
          );
        } else {
          if (imgProgress === 100) {
            toast.update(imgtoast, { progress: imgProgress, autoClose: true });
            imgtoast = null;
          } else {
            toast.update(imgtoast, {
              render: "Submitting Resume: " + imgProgress.toFixed(1) + "%",
            });
          }
        }
      },
      (error) => {
        toast.error(error.message, { delay: 2000, position: "top-right" });
      },
      () => {
        saveImg.snapshot.ref.getDownloadURL().then((downloadURL) => {
          newProductRef
            .set({
              name: this.state.name,
              id: newProductRef.key,
              pdfUrl: downloadURL,
              email: this.state.email,
              contact: this.state.contact,
              dob: this.state.dob,
              address: this.state.address,
              spmuec: this.state.spmuec,
              academic: this.state.academic,
              expectedSalart: this.state.expectedSalart,
              doa: this.state.doa,
            })
            .then(() => {
              toast.success(
                "Thanks for your interested! We will contact you shortly!",
                { delay: 2500, position: "top-right" }
              );
            });
        });
      }
    );
  };
  componentDidMount = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    return (
      <Wrapper>
        <BackWrapper>
          <Back
            onClick={() => {
              this.props.history.push("/");
            }}
          >
            <IoMdArrowBack />
            Back
          </Back>
        </BackWrapper>
        <TopWrapper>
          <JobTitle>Working at Y W Soo & Co.</JobTitle>
          <ServiceDetail>
            Enjoy work life balance and accelerate your career as an Audit. Y W
            Soo & Co values talent over anything. We take pride in taking care
            of our team and assuring everyone's growth.
          </ServiceDetail>
          <img alt="team" src={team} width="100%" height="auto" />
        </TopWrapper>
        <BottomWrapper>
          <SectionWrapper>
            <JobSubtitle>Accelerate your career as an Audit</JobSubtitle>
            <Subtitle>What we offer:</Subtitle>
            <ServiceDetail>
              &nbsp;&nbsp;- &nbsp;&nbsp;Flexible compensation open to discussion
              with competitive overtime compensation. <br />
              &nbsp;&nbsp;- &nbsp;&nbsp;Balance work days: 5 days per week{" "}
              <br />
              &nbsp;&nbsp;- &nbsp;&nbsp;Parking Allowance: RM80 per month after
              probation.
              <br />
              &nbsp;&nbsp;- &nbsp;&nbsp;Medical fee claimable (Do not apply to
              chronic disease)
              <br />
              &nbsp;&nbsp;- &nbsp;&nbsp;Study leave for accounting related
              qualification
              <br />
            </ServiceDetail>
          </SectionWrapper>
          <Subtitle>Apply now</Subtitle>
          <FormWrapper onSubmit={this._submitForm}>
            <RowDiv>
              <Input2
                title="Full Name"
                id="jobName"
                className="jobName"
                changeValue={(e) => {
                  this.setState({ name: e });
                }}
              />
              <Input2
                title="Date of birth (dd/mm/yyyy)"
                id="jobDob"
                className="jobDob"
                changeValue={(e) => {
                  this.setState({ dob: e });
                }}
              />
            </RowDiv>
            <RowDiv>
              <Input2
                title="Email"
                id="jobEmail"
                className="jobEmail"
                changeValue={(e) => {
                  this.setState({ email: e });
                }}
              />
              <Input2
                title="Contact No"
                id="jobContact"
                className="jobContact"
                changeValue={(e) => {
                  this.setState({ contact: e });
                }}
              />
            </RowDiv>
            <Input2
              title="Address"
              id="jobAddress"
              className="jobAddress"
              changeValue={(e) => {
                this.setState({ address: e });
              }}
            />
            <RowDiv>
              <Input2
                title="SPM/UEC Result"
                id="jobSpmUec"
                className="jobSpmUec"
                changeValue={(e) => {
                  this.setState({ spmuec: e });
                }}
              />
              <Input2
                title="Academic Result"
                id="jobAcademic"
                className="jobAcademic"
                changeValue={(e) => {
                  this.setState({ academic: e });
                }}
              />
            </RowDiv>
            <RowDiv>
              <Input2
                title="Expected Salary (RM)"
                id="jobSalary"
                className="jobSalary"
                changeValue={(e) => {
                  this.setState({ expectedSalart: e });
                }}
              />
              <Input2
                title="When can you start working?"
                id="jobName"
                className="jobName"
                changeValue={(e) => {
                  this.setState({ doa: e });
                }}
              />
            </RowDiv>
            <FileInput id="resume" placeholder="Select a file" />
            <Button onClick={this._submitForm} text="Apply for job" />
          </FormWrapper>
        </BottomWrapper>
      </Wrapper>
    );
  }
}
