import React from 'react'
import styled from 'styled-components'
import PulseLoader from 'react-spinners/PulseLoader'

const Wrapper = styled.button`
    padding: 16px 24px;
    border-radius: 40px;
    color: #f1f3f6;
    box-shadow: ${props => props.theme.shadow1};
    transition: 0.6s;
    background-color: ${props => props.theme.blackBlue};
    cursor: pointer;
    border-style: none;
    letter-spacing: 0.1em;
    font-size: 14px;
    font-family: ${props => props.theme.font3};
    :hover{
        box-shadow: ${props => props.theme.shadow2};
        opacity: 0.8
    }
    @media(max-width: 639px){
        font-size: 12px;
    }
`

export default ({onClick, text, loading = false}) => {

    return(
        <Wrapper onClick={loading ? () => {} : onClick}>
            {loading ? <PulseLoader color="#f1f3f6" size={8}/> : text}
        </Wrapper>
    )
}