import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    height: 50px;
    margin: 20px 0px;
    position: relative;
    padding-left: 5px;
    overflow: hidden;
    @media(min-width: 769px){
        width: 100%;
        margin-right: 5%;
    }
    background-color: transparent;
`;

const Box = styled.input`
    width: 100%;
    height: 100%;
    color: #595f6e;
    border-style: none;
    padding-top: 20px;
    background-color: transparent;
    outline: none;
    font-size: 14px;
    :focus + .${props => props.title}, :valid + .${props => props.title}{
        color: ${props => props.theme.darkBlue};
        padding-top: 0px;
        font-weight: 800;
    }
    :focus + .${props => props.title}:after, :valid + .${props => props.title}:after{
        transform: translateX(0%);
    }
`;

const Title = styled.label`
    position: absolute;
    font-family: ${props => props.theme.font3};
    height: 100%;
    transition: all 0.3s ease;
    width: 100%;
    left: 0px;
    padding-top: 20px;
    pointer-events: none;
    border-bottom: 1px solid #595f6e;
    :after{
        content: '';
        display: block;
        position: absolute;
        transition: transform 0.5s ease;
        width: 100%;
        height: 100%;
        bottom: -2px;
        transform: translateX(-100%);
        border-bottom: 3px solid ${props => props.theme.darkBlue};
    }
`;

export default ({ type = "text", placeholder, title, changeValue, required = true, id, inputId, className}) => {

    const [data, setData] = useState("");

    function updateData(newData){
    changeValue(newData)
    }

    return(
        <Wrapper id={id}>
            <Box required={required} id={inputId} type={type} value={data} title={className} onChange={e => {
                setData(e.target.value)
                updateData(e.target.value)
            }}/>
            <Title className={className}>
                {title}
            </Title>
        </Wrapper>
    )
    }