import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    border-style: solid;
    border-bottom-width: 1px;
    width: 100%;
    border-color: ${props => props.theme.darkGrey};
`

const Label = styled.label`
    font-family: ${props => props.theme.font4};
    padding: 12px 0px;
    margin-right: 8px;
`
const Input = styled.input`
    width: 100%;
    background-color: transparent;
    border-style: none;
    border-radius: 8px;
    padding: 12px 24px;
    color: ${props => props.theme.darkGrey};
`

export default ({placeholder, label = "", error = false, value, onChange}) => {

    return(
        <Wrapper>
            <Label>
               {label}     
            </Label>
            <Input border={error ? '#E83F34' : '#f1f3f6'} placeholder={placeholder} value={value} onChange={onChange} />
        </Wrapper>
    )
}