import React, { Component } from "react";
import { IoIosCall, IoIosMail, IoIosPin } from "react-icons/io";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled, { keyframes } from "styled-components";
import Menu from "../components/Menu";
import officeimg from "../components/img/office.jpeg";
import mr_soo from "../components/img/mr_soo.jpg";
import tax from "../components/img/tax.png";
import audit from "../components/img/audit.png";
import desk from "../components/img/desk.png";
import salary from "../components/img/salary.png";
import add from "../components/img/add.png";
import book from "../components/img/book.png";
import Button from "../components/Button";
import Input from "../components/Input";
import * as firebase from "firebase";

toast.configure();

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const First = styled.div`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: -2;
  object-fit: cover;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Second = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 80px;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const ThirdTopWrapper = styled.div`
  width: 100%;
  height: 250px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 40px 80px;
  @media (max-width: 639px) {
    padding: 24px 34px;
  }
`;

const ThirdBottomWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 250px);
  background-color: ${(props) => props.theme.darkBlue};
  padding: 40px 80px;
  overflow: hidden;
  @media (max-width: 639px) {
    padding: 24px 34px;
  }
`;

const Third = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -2;
`;
const Forth = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
const Fifth = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f1f3f6;
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    min-height: 100vh;
  }
`;

const FifthTopWrapper = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 80px;
  @media (max-width: 639px) {
    padding: 60px 34px;
  }
`;

const FifthBottomWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.darkBlue};
  padding: 40px 80px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    padding: 40px 20px;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
  }
`;

const slideUp = keyframes`
  0% {
    transform: translateY(100%);
  }
  100%{
    transform: translateY(0%);
  }
`;

const Title = styled.h1`
  color: #1e1a58;
  font-size: 28px;
  font-family: ${(props) => props.theme.font4};
  font-weight: 800;
  line-height: 40px;
  z-index: 1;
  transition: 0.6s;
  opacity: ${(props) => props.tOpacity};
  text-align: left;
  animation: ${slideUp} 0.6s;
  @media (max-width: 768px) {
    font-size: 18px;
    text-align: center;
    line-height: 26px;
  }
`;

const Subtitle = styled.div`
  font-size: 20px;
  font-family: "Google Sans", "Roboto", sans-serif;
  color: ${(props) => props.theme.darkGrey};
  font-weight: 700;
  cursor: default;
  @media (max-width: 639px) {
    text-align: center;
  }
`;

const SooTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 16px;
  @media (max-width: 639px) {
    flex-direction: column;
  }
`;

const Box = styled.div`
  ${(props) => props.theme.whiteBox};
  height: 100%;
  width: 100%;
  padding: 0px;
  overflow: hidden;
  margin: 8px;
  @media (max-width: 1024px) {
    padding: 40px 0px;
  }
  transition: 0.6s;
  :hover {
    background-color: white;
    box-shadow: ${(props) => props.theme.shadow2};
  }
`;

const MRSooWrapper = styled.div`
  position: relative;
  margin-top: 100px;
  display: block;
  width: 50%;
  height: 150px;
  justify-content: flex-end;
`;

const MRSoo = styled.img`
  position: absolute;
  right: 0;
  margin-right: -${(props) => props.sooImgPosition}px;
  opacity: ${(props) => props.sooTitleOpacity};
  border-radius: 12px;
  transition: 1.2s;
  width: 60%;
  box-shadow: ${(props) => props.theme.shadow1};
  @media (max-width: 639px) {
    width: 90%;
    margin-top: 40%;
    height: auto;
  }
`;

const B = styled.b`
  font-weight: 800;
`;

const SooTitle = styled.h1`
  font-size: 28px;
  font-family: "Google Sans", "Roboto", sans-serif;
  color: #000000;
  font-weight: 800;
  line-height: 38px;
  text-align: left;
  opacity: ${(props) => props.sooTitleOpacity};
  margin-left: -${(props) => props.sooTitlePosition}px;
  transition: 0.6s;
  @media (max-width: 639px) {
    font-size: 16px;
    max-width: 150px;
  }
`;

const SubtitleSoo = styled.h3`
  font-size: 18px;
  font-family: ${(props) => props.theme.font3};
  line-height: 32px;
  letter-spacing: 0.1em;
  opacity: ${(props) => props.sooTitleOpacity};
  margin-left: -${(props) => props.sooTitlePosition}px;
  color: ${(props) => props.theme.darkGrey};
  font-weight: 400;
  cursor: default;
  transition: 1.2s;
  @media (max-width: 639px) {
    font-size: 12px;
  }
`;

const ServiceDetail = styled.p`
  font-size: 16px;
  font-family: ${(props) => props.theme.font3};
  line-height: 26px;
  cursor: default;
  margin-top: 16px;
  color: ${(props) => props.theme.darkGrey};
  font-weight: 400;
  max-width: 640px;
  @media (max-width: 639px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

const SooIntroduction = styled.p`
  font-size: 16px;
  font-family: ${(props) => props.theme.font3};
  line-height: 26px;
  cursor: default;
  margin-top: ${(props) => props.sooIntroductionPosition}px;
  opacity: ${(props) => props.sooTitleOpacity};
  color: #ffffff;
  font-weight: 400;
  max-width: 640px;
  transition: 1.2s;
  @media (max-width: 639px) {
    font-size: 11px;
  }
`;

const ServiceWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  @media (max-width: 639px) {
    padding: 24px 32px;
    align-items: center;
  }
`;

const Icon = styled.img`
  max-height: 120px;
  max-width: 120px;
  margin-bottom: 16px;
`;

const ServicesTitle = styled.h1`
  font-size: 28px;
  width: 100%;
  font-family: "Google Sans", "Roboto", sans-serif;
  color: ${(props) => props.theme.darkGrey};
  font-weight: 600;
  margin-bottom: 12px;
  text-align: center;
  @media (max-width: 639px) {
    font-size: 26px;
    text-align: left;
    margin-top: 40px;
  }
`;

const JobSubtitle = styled.h3`
  font-family: ${(props) => props.theme.font4};
  font-size: 14px;
  color: ${(props) => props.theme.blackBlue};
  letter-spacing: 0.1em;
  @media (max-width: 639px) {
    font-size: 12px;
  }
`;

const JobTitle = styled.h1`
  font-family: ${(props) => props.theme.font3};
  font-size: 28px;
  font-weight: 900;
  margin-top: 12px;
  letter-spacing: 0.1em;
  margin-bottom: 36px;
  color: ${(props) => props.theme.blackBlue};
  @media (max-width: 639px) {
    font-size: 23px;
    letter-spacing: 0.05em;
  }
`;

const JobDescriptionWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f1f3f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 10vh;
  @media (max-width: 639px) {
    padding: 20px;
  }
`;

const JobRow = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const JobImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 26px;
  background-color: ${(props) => props.bg};
  @media (max-width: 639px) {
    padding: 20px;
  }
`;

const JobButtonWrapper = styled.div``;
const JobImgText = styled.p`
  font-family: ${(props) => props.theme.font4};
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.color || props.theme.darkGrey};
  text-align: center;
  @media (max-width: 639px) {
    line-height: 18px;
    font-size: 12px;
  }
`;

const JobImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: grey;
  display: flex;
  flex-direction: column;
`;

const ContactTitle = styled.h1`
  font-size: 28px;
  width: 100%;
  font-family: "Google Sans", "Roboto", sans-serif;
  color: ${(props) => props.theme.darkGrey};
  font-weight: 800;
  margin-bottom: 12px;
  text-align: center;
  @media (max-width: 639px) {
    font-size: 22px;
  }
`;
const ContactSubtitle = styled.p`
  font-size: 20px;
  width: 100%;
  text-align: center;
  font-family: "Google Sans", "Roboto", sans-serif;
  color: ${(props) => props.theme.darkGrey};
  line-height: 30px;
  margin-bottom: 12px;
  text-align: center;
  text-spacing: 0.2em;
  @media (max-width: 639px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

const JobImg = styled.img`
  height: auto;
  width: 30%;
  object-fit: cover;
  margin-bottom: 12px;
`;

const EnquiryForm = styled.div`
  ${(props) => props.theme.whiteBox}
  margin-top: -100px;
  width: 100%;
  padding: 40px 40px;
  height: auto;
  box-shadow: ${(props) => props.theme.shadow1};
  background-color: white;
  @media (max-width: 768px) {
    padding: 40px 20px;
    width: 100%;
  }
`;

const ContactWrapper = styled.div`
  width: 50%;
  padding: 0px 40px;
  color: #f1f3f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 20px 0px;
    width: 100%;
  }
`;

const ContactInfoWrapper = styled.div`
  margin: 12px 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

const ContactIcon = styled.div`
  margin-right: 12px;
`;
const ContactDetail = styled.p`
  font-size: 20px;
  width: 100%;
  line-height: 30px;
  text-align: left;
  font-family: "Google Sans", "Roboto", sans-serif;
  color: #f1f3f6;
  text-spacing: 0.2em;
  @media (max-width: 639px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

const EnquiryFormTitle = styled.h1`
  font-size: 28px;
  width: 100%;
  font-family: "Google Sans", "Roboto", sans-serif;
  color: ${(props) => props.theme.darkGrey};
  font-weight: 800;
  margin-bottom: 12px;
  text-align: left;
  @media (max-width: 639px) {
    font-size: 22px;
  }
`;

const slideLeft = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
`;

const OfficeImage = styled.img`
  max-height: 100vh;
  animation: ${slideLeft} 0.8s;
  right: 0;
  @media (max-width: 768px) {
    height: 100vh;
    object-fit: cover;
    width: 100vw;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 20px;
    align-items: center;
    height: 60vh;
  }
`;

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.scrollAction = this.scrollAction.bind(this);
    this.state = {
      opacity: 0,
      position: 200,
      imgOpacity: 0,
      imgPosition: 60,
      sooTitleOpacity: 0,
      sooTitlePosition: 300,
      sooImgPosition: 600,
      name: "",
      phone: "",
      email: "",
      enquiries: "",
      submitLoading: false,
      sooIntroductionPosition: 200,
      homeRef: React.createRef(),
      serviceRef: React.createRef(),
      aboutRef: React.createRef(),
      careerRef: React.createRef(),
      contactRef: React.createRef(),
    };
  }

  scrollAction = () => {
    let p = window.pageYOffset;
    if (p >= (this.state.serviceRef.current.offsetTop * 2) / 3) {
      if (this.state.opacity === 1) {
        this.setState({
          opacity: 0,
          position: 200,
          imgOpacity: 0,
          imgPosition: 60,
        });
      }
    } else {
      if (this.state.opacity === 0) {
        this.setState({
          opacity: 1,
          position: 0,
          imgPosition: 50,
        });
      }
    }
    if (p >= this.state.aboutRef.current.offsetTop - 200) {
      if (this.state.sooTitleOpacity === 0) {
        this.setState({
          sooTitleOpacity: 1,
          sooTitlePosition: 0,
          sooImgPosition: 15,
          sooIntroductionPosition: 24,
        });
      }
    } else {
      if (this.state.sooTitleOpacity === 1) {
        this.setState({
          sooTitleOpacity: 0,
          sooTitlePosition: 300,
          sooImgPosition: 600,
          sooIntroductionPosition: 200,
        });
      }
    }
  };

  _submitForm = (e) => {
    e.preventDefault();
    this.setState({
      submitLoading: true,
    });
    if (this.state.name === "") {
      toast.error("Your name is required!", { position: "bottom-left" });
      return this.setState({
        nameError: true,
        submitLoading: false,
      });
    }
    if (this.state.phone === "") {
      toast.error("Your phone is required!", { position: "bottom-left" });
      return this.setState({
        phoneError: true,
        submitLoading: false,
      });
    }
    if (this.state.email === "") {
      toast.error("Your email is required!", { position: "bottom-left" });
      return this.setState({
        emailError: true,
        submitLoading: false,
      });
    }
    const enquiryRef = firebase.database().ref("enquiries");
    let newEnquiryRef = enquiryRef.push();
    newEnquiryRef
      .set({
        name: this.state.name,
        phone: this.state.phone,
        email: this.state.email,
        enquiries: this.state.enquiries,
      })
      .then(() => {
        this.setState({
          submitLoading: false,
        });
        toast.success(
          "Thanks! Our team will contact you within 1 working day!",
          {
            position: "bottom-left",
            autoClose: 3000,
            onClose: () => {
              window.location.reload();
            },
          }
        );
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          submitLoading: false,
        });
      });
  };

  componentDidMount = () => {
    window.addEventListener("scroll", this.scrollAction);
    setTimeout(() => {
      this.setState({
        opacity: 1,
        position: 0,
        imgPosition: 50,
        sooTitleOpacity: 1,
        sooTitlePosition: 0,
        sooImgPosition: 15,
        sooIntroductionPosition: 36,
      });
    }, 100);
  };

  render() {
    return (
      <>
        <Menu
          homeRef={this.state.homeRef}
          serviceRef={this.state.serviceRef}
          aboutRef={this.state.aboutRef}
          careerRef={this.state.careerRef}
          contactRef={this.state.contactRef}
        />
        <Wrapper>
          <Section ref={this.state.homeRef}>
            <First>
              <TitleWrapper>
                <Title tOpacity={this.state.opacity}>
                  Professional audit service with <br />
                  more than just experience.
                </Title>
              </TitleWrapper>
              <OfficeImage src={officeimg} alt="office" />
            </First>
          </Section>
          <Section ref={this.state.serviceRef}>
            <Second>
              <ServicesTitle>Our services</ServicesTitle>
              <ServicesWrapper>
                <Box>
                  <ServiceWrapper>
                    <Icon src={audit} />
                    <Subtitle>Statutory Audits</Subtitle>
                    <ServiceDetail>
                      Statutory external audit is our core function in the firm.
                      External audit is generally required to be conducted
                      annually to meet a specific set of requirements by the
                      government and to determine whether the company is
                      providing a true and fair view on its financial
                      statements. It relies on the internal audit reports,
                      verification of financial information and documents. Scope
                      and nature of audit program are subject to the
                      specifications of the governmental body and financial
                      institutions.
                    </ServiceDetail>
                  </ServiceWrapper>
                </Box>
                <Box>
                  <ServiceWrapper>
                    <Icon src={tax} />
                    <Subtitle>Tax Services</Subtitle>
                    <ServiceDetail>
                      We assist companies to manage the tax compliance matters
                      such as follows:
                      <br />
                      <br /> &nbsp;&nbsp;- Preparation and filing of tax returns
                      <br /> &nbsp;&nbsp;- Filing of tax estimates
                      <br /> &nbsp;&nbsp;- Assistances on handling tax audits
                      <br /> &nbsp;&nbsp;- Identifying current tax laws /
                      legislations
                      <br /> &nbsp;&nbsp;- Complying with the relevant statutory
                      provisions
                    </ServiceDetail>
                  </ServiceWrapper>
                </Box>
              </ServicesWrapper>
            </Second>
          </Section>
          <Section ref={this.state.aboutRef}>
            <Third>
              <ThirdTopWrapper>
                <SooTitleWrapper>
                  <SooTitle
                    sooTitleOpacity={this.state.sooTitleOpacity}
                    sooTitlePosition={this.state.sooTitlePosition}
                  >
                    Mr Soo Yuit Weng
                  </SooTitle>
                  <SubtitleSoo
                    sooTitleOpacity={this.state.sooTitleOpacity}
                    sooTitlePosition={this.state.sooTitlePosition}
                  >
                    Chartered Accountant
                  </SubtitleSoo>
                </SooTitleWrapper>
                <MRSooWrapper>
                  <MRSoo
                    width="80%"
                    height="auto"
                    sooImgPosition={this.state.sooImgPosition}
                    sooTitleOpacity={this.state.sooTitleOpacity}
                    src={mr_soo}
                  />
                </MRSooWrapper>
              </ThirdTopWrapper>
              <ThirdBottomWrapper>
                <SooIntroduction
                  sooIntroductionPosition={this.state.sooIntroductionPosition}
                  sooTitleOpacity={this.state.sooTitleOpacity}
                >
                  Mr. Soo Yuit Weng, founder of Y W Soo & Co, holds a Bachelor
                  of Economics from Monash University, Australia majoring in
                  Accounting. He is a member of Malaysian Institute of
                  Accountants and a fellow of Chartered Tax Institute (CTIM). He
                  is also a licensed Auditor and Tax Agent in Malaysia. He is a
                  Chartered Accountant and is currently practicing under his own
                  firm Y W Soo & Co. Prior to that, Mr. Soo was attached to
                  various firms and has extensive in the field of audit and
                  taxation. He is currently: <br />
                  <B>
                    <br />
                    1. Independent Non-Executive Director of Kelington Group
                    Berhad (listed in Bursa Malaysia)
                    <br />
                    2. Perak Branch Committee Member of CTIM
                    <br />
                    3. Panel member for Advocates & Solicitors Disciplinary
                    Board of the Malaysian Bar
                    <br />
                  </B>
                </SooIntroduction>
              </ThirdBottomWrapper>
            </Third>
          </Section>
          <Section ref={this.state.careerRef}>
            <Forth>
              <JobDescriptionWrapper>
                <JobSubtitle>ACCELERATE YOUR CAREER AS AN AUDIT</JobSubtitle>
                <JobTitle>Working at Y W Soo & Co</JobTitle>
                <JobButtonWrapper>
                  <Button
                    text="View position"
                    onClick={() => {
                      window.removeEventListener("scroll", this.scrollAction);
                      this.props.history.push("/career");
                    }}
                  />
                </JobButtonWrapper>
              </JobDescriptionWrapper>
              <JobImageWrapper>
                <JobRow>
                  <JobImgWrapper bg="#f1f3f6">
                    <JobImg src={desk} />
                    <JobImgText>
                      Balanced work life and oversea company trip
                    </JobImgText>
                  </JobImgWrapper>
                  <JobImgWrapper bg="#4B70B9">
                    <JobImg src={salary} />
                    <JobImgText color="#f1f3f6">
                      Competitive salary and overtime compensation
                    </JobImgText>
                  </JobImgWrapper>
                </JobRow>
                <JobRow>
                  <JobImgWrapper bg="#4B70B9">
                    <JobImg src={add} />
                    <JobImgText color="#f1f3f6">
                      Medical fee and parking allowance claimable
                    </JobImgText>
                  </JobImgWrapper>
                  <JobImgWrapper bg="#f1f3f6">
                    <JobImg src={book} />
                    <JobImgText>
                      Study leave for accounting related qualification
                    </JobImgText>
                  </JobImgWrapper>
                </JobRow>
              </JobImageWrapper>
            </Forth>
          </Section>
          <Section ref={this.state.contactRef}>
            <Fifth>
              <FifthTopWrapper>
                <ContactTitle>Contact us</ContactTitle>
                <ContactSubtitle>
                  Ready to work with the best Audit firm in town? <br />
                  Give us a call or drop us an enquiry below!
                </ContactSubtitle>
              </FifthTopWrapper>
              <FifthBottomWrapper>
                <div style={{ width: "100%" }}>
                  <EnquiryForm>
                    <EnquiryFormTitle>Enquiry form</EnquiryFormTitle>
                    <form
                      onSubmit={(e) => e.preventDefault()}
                      style={{ marginBottom: 16 }}
                    >
                      <Input
                        placeholder="Name"
                        required={true}
                        error={this.state.nameError}
                        value={this.state.name}
                        onChange={(e) => {
                          this.setState({
                            name: e.target.value,
                          });
                          if (e.target.value === "") {
                            this.setState({
                              nameError: true,
                            });
                          } else {
                            this.setState({
                              nameError: false,
                            });
                          }
                        }}
                      />
                      <Input
                        placeholder="Phone-no"
                        required={true}
                        error={this.state.phoneError}
                        value={this.state.phone}
                        onChange={(e) => {
                          this.setState({
                            phone: e.target.value,
                          });
                          if (e.target.value === "") {
                            this.setState({
                              phoneError: true,
                            });
                          } else {
                            this.setState({
                              phoneError: false,
                            });
                          }
                        }}
                      />
                      <Input
                        placeholder="E-mail"
                        required={true}
                        error={this.state.emailError}
                        value={this.state.email}
                        onChange={(e) => {
                          this.setState({
                            email: e.target.value,
                          });
                          if (e.target.value === "") {
                            this.setState({
                              emailError: true,
                            });
                          } else {
                            this.setState({
                              emailError: false,
                            });
                          }
                        }}
                      />
                      <Input
                        placeholder="Anything else?"
                        error={this.state.enquiriesError}
                        value={this.state.enquiries}
                        onChange={(e) => {
                          this.setState({
                            enquiries: e.target.value,
                          });
                        }}
                      />
                    </form>
                    <Button
                      text="Contact Sales"
                      loading={this.state.submitLoading}
                      onClick={this._submitForm}
                    />
                  </EnquiryForm>
                </div>
                <ContactWrapper>
                  <ContactInfoWrapper>
                    <ContactIcon>
                      <IoIosCall size={28} />
                    </ContactIcon>
                    <ContactDetail>05-249 7733</ContactDetail>
                  </ContactInfoWrapper>
                  <ContactInfoWrapper>
                    <ContactIcon>
                      <IoIosMail size={28} />
                    </ContactIcon>
                    <ContactDetail>bien@ywsoo.my</ContactDetail>
                  </ContactInfoWrapper>
                  <ContactInfoWrapper>
                    <ContactIcon>
                      <IoIosPin size={28} />
                    </ContactIcon>
                    <ContactDetail>
                      60A, Jalan Leong Sin Nam, 30300 Ipoh, Perak.
                    </ContactDetail>
                  </ContactInfoWrapper>
                </ContactWrapper>
              </FifthBottomWrapper>
            </Fifth>
          </Section>
        </Wrapper>
      </>
    );
  }
}
