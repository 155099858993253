import React from 'react';
import { ThemeProvider } from 'styled-components'
import GlobalStyles from './styles/GlobalStyles'
import Theme from './styles/Theme';
import Nav from './components/Nav'
import Footer from './components/Footer'
import { HashRouter as Router} from 'react-router-dom'

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <div className="App">
        <GlobalStyles />
        <Router>
          <Nav />
        </Router>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
