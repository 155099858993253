import React from 'react';
import styled from 'styled-components'

const Wrapper = styled.input`
    ${props => props.theme.whiteBox}
    width: 90%;
    border-style: solid;
    border-width: 1px;
    border-color: ${props => props.theme.darkGrey};
    cursor: pointer;
    :hover{
        border-color: ${props => props.theme.blue};
    }
    margin-bottom: 16px;
`
const Title = styled.label`
    font-family: ${props => props.theme.font3};
    margin-bottom: 8px;
`;

export default ({id, placeholder}) => {
    return(
        <div style={{display: "flex", flexDirection: 'column'}}>
            <Title>Resume Attachment (Drag a file to upload)</Title>
        <Wrapper id={id} placeholder={placeholder}type="file">
        </Wrapper>
        </div>
    )
}