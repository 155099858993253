import React from 'react';
import { Switch, Route } from 'react-router-dom'
import Home from '../routes/Home'
import Career from '../routes/Career'

export default () => {
    return(
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/career" component={Career}/>
        </Switch>
    )
}